<template>
  <div
    class="data-card"
    :class="{ card: listType == 'card', list: listType == 'list' }"
    @click="jump"
  >
    <div class="tag" v-show="listType == 'card'">
      <span class="green" v-if="dataSource.status == 1">
        {{ $t("Evaluated")
        }}<!-- 已评估 -->
      </span>
      <span class="red" v-else>
        {{ $t("homework.NotEvaluated")
        }}<!-- 未评估 -->
      </span>
    </div>
    <div class="cover">
      <img :src="dataSource.coverImg" alt="cover" />
    </div>
    <div class="detail">
      <h5>
        <template v-if="listType == 'list'">
          <span class="status bg-green" v-if="dataSource.status == 1">
            {{ $t("Evaluated")
            }}<!-- 已评估 -->
          </span>
          <span class="status bg-red" v-else>
            {{ $t("homework.NotEvaluated")
            }}<!-- 未评估 -->
          </span>
        </template>
        <a-popover trigger="hover">
          <template #content>
            <div class="popover-content">
              {{ dataSource.detailName }}
            </div>
          </template>
          {{ dataSource.detailName }}
        </a-popover>
      </h5>
      <div class="row">
        <span>{{ $t("EvaluationQuantity") }}：</span>
        <span class="green">{{ dataSource.waits }}</span
        >/{{ dataSource.totals }}
      </div>
      <div class="row">
        <span> {{ $t("EvaluateStartTime") }}： </span>
        <span class="fs12">
          {{ dateFormat(dataSource.startTime) }}
        </span>
        <!-- 评估开始时间 -->
      </div>
      <div class="row" v-show="listType == 'list'">
        <span> {{ $t("FaceToFaceSource") }}： </span>
        <a-tooltip>
          <template #title>
            <template v-if="dataSource.taskType">
              【{{ taskCardType[dataSource.taskType].name }}】
              {{ dataSource.projectName }}
              {{ dataSource.taskName }}
            </template>
            <template v-else>
              【{{ $t("CM_CT") }}】{{ dataSource.detailName }}
            </template>
          </template>
          <template v-if="dataSource.taskType">
            【{{ taskCardType[dataSource.taskType].name }}】
            {{ dataSource.projectName }}-
            {{ dataSource.taskName }}
          </template>
          <template v-else>
            【{{ $t("CM_CT") }}】{{ dataSource.detailName }}
          </template>
        </a-tooltip>
      </div>
    </div>
    <div class="btns" v-show="listType == 'list'">
      <div class="btn">
        {{ $t("view_detail") }}
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { dateFormat } from "@/utils/tools";
import { taskCardType } from "@/utils/business";
export default {
  name: "evaluateCard",
  props: {
    listType: {
      type: String,
      default: "card",
    },
    dataSource: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const router = useRouter();
    const jump = () => {
      router.push({
        path: "/mine/evaluate/detail",
        query: {
          id: props.dataSource.taskId,
          did: props.dataSource.detailId,
        },
      });
    };

    return {
      dateFormat,
      taskCardType,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
